import udaLoginImage from '../../assets/images/img-login-pulse.png';
import { LoginImageContainer, LoginImageWrapper } from 'Auth/forms/AuthForms.styles';
import { useBreakpoint } from 'utils/hooks/useBreakpoint';

const AuthBrand = () => {
  const breakpoint = useBreakpoint();
  const isMobileOrTablet = breakpoint === 's' || breakpoint === 'm';
  return (
    <LoginImageContainer>
      {!isMobileOrTablet && <LoginImageWrapper src={udaLoginImage} />}
    </LoginImageContainer>
  );
};

export default AuthBrand;
