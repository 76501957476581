import { NavigationBar } from '@uda/bento-components';
import logo from '../assets/images/logo-opportunities.svg';
import logoMobile from '../assets/images/logo-opportunities-mobile.svg';
import { Link } from 'react-router-dom';

const StaticLayout = ({ children }) => {
  return (
    <>
      <NavigationBar
        header={
          <Link to={'/'}>
            <picture>
              <source srcSet={logoMobile} media="(max-width: 600px), (max-height: 700px)" />

              <img src={logo} alt={'Pulse'} />
            </picture>
          </Link>
        }
      />
      <div>{children}</div>
    </>
  );
};

export default StaticLayout;
