import { getNewRangeFilters } from '../../../../utils/filters';
import { getReversGeocodingAddress, getAutocompleteAddresses } from '../../../../utils/geocoding';
import { apiInternTable, apiInternFiltersApplied } from './apiIntern';
import { formatSelectedAssets, formatSelectedComparables, formatSelectedRows } from './intern';

export const initialState = {
  list: {
    assetId: null,
    isLoading: true,
    isLoadingPagination: false,
    initialColumnState: [],
    columnState: null,
    columns: [],
    rows: [],
    sorting: {},
    pagination: {}
  },
  filters: null,
  filtersByAnchor: [],
  idAnchor: null,
  defaultValues: {
    filtersApplied: null,
    map: null
  },
  filtersApplied: {},
  map: null,
  assetMapSelected: null,
  isLoadingAutocomplete: null,
  autocomplete: [],
  selectedAssets: {},
  defaultFiltersAsset: null,
  anchor_features: null,
  gridApi: null,
  comparablesReqStatus: null,
  sourceInFamilies: null,
  comparablesListCallEnded: false
};

const reducer = (state = initialState, { type, payload, ...action }) => {
  switch (type) {
    case 'FETCH_COMPARABLES_LIST':
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true
        }
      };
    case 'FETCH_COMPARABLES_LIST_SUCCESS':
      if (
        payload.data.id === 'MONGO_ERROR' &&
        payload.data.detail === 'Request comparables timeout'
      ) {
        return {
          ...state,
          comparablesReqStatus: 504
        };
      } else {
        state.comparablesListCallEnded = true;
        const initialComparables = apiInternTable(payload.data);
        const comparables = formatSelectedComparables(initialComparables, state.selectedAssets);

        return {
          ...state,
          list: {
            ...state.list,
            isLoading: false,
            ...comparables
          },
          comparablesReqStatus: payload?.status ?? null
        };
      }
    case 'FETCH_COMPARABLES_LIST_FAIL':
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          initialColumnState: [],
          columnState: null,
          columns: [],
          rows: [],
          sorting: {},
          pagination: {}
        },
        comparablesReqStatus: payload?.error ?? null
      };
    case 'RESET_COMPARABLES_REQ_STATUS':
      return {
        ...state,
        comparablesReqStatus: null
      };
    case 'COMPARABLES_LIST_SORT':
      return {
        ...state,
        list: {
          ...state.list,
          sorting: payload
        }
      };
    case 'SET_COMPARABLES_MAP':
      return {
        ...state,
        map: {
          ...state.map,
          ...payload
        },
        assetMapSelected: null
      };
    case 'SET_ASSET_MAP_SELECTED':
      return {
        ...state,
        assetMapSelected: {
          ...state.assetMapSelected,
          ...payload
        }
      };
    case 'RESET_ASSET_MAP_SELECTED':
      return {
        ...state,
        assetMapSelected: null
      };
    case 'FETCH_ASSET_MAP_SELECTED_SUCCESS':
      return {
        ...state,
        assetMapSelected: {
          ...state.assetMapSelected,
          address: getReversGeocodingAddress(payload.data)
        }
      };
    case 'FETCH_ASSET_MAP_SELECTED_FAIL':
      return {
        ...state,
        assetMapSelected: {
          ...state.assetMapSelected,
          address: null
        }
      };
    case 'FETCH_COMPARABLES_FILTERS_SUCCESS':
      state.defaultFiltersAsset = payload.data.filters_applied;
      let filters;
      const sourceInFilter = payload.data.filters.secondary
        .flat()
        .find(item => item.kpi === 'source__in');
      state.sourceInFamilies = sourceInFilter ? sourceInFilter.values : [];
      let aux = state?.filtersByAnchor?.find(item => item?.idAnchor === state?.idAnchor);
      if (aux) {
        filters = aux.filters;
      } else {
        filters = payload.data.filters_applied;
      }

      const initialFiltersApplied = apiInternFiltersApplied(filters);
      return {
        ...state,
        filters: payload.data.filters,
        defaultValues: {
          filtersApplied: initialFiltersApplied,
          map: payload.data.map
        },
        filtersApplied: initialFiltersApplied,
        map: payload.data.map,
        selectedAssets: payload.data.selected_comparables || {},
        anchor_features: payload.data?.anchor_features ?? null
      };
    case 'FETCH_AUTOCOMPLETE_MAP':
      return {
        ...state,
        assetMapSelected: null,
        isLoadingAutocomplete: true,
        autocomplete: []
      };
    case 'FETCH_AUTOCOMPLETE_MAP_SUCCESS':
      return {
        ...state,
        assetMapSelected: null,
        isLoadingAutocomplete: false,
        autocomplete: getAutocompleteAddresses(payload.data)
      };
    case 'FETCH_AUTOCOMPLETE_MAP_FAIL':
      return {
        ...state,
        assetMapSelected: null,
        isLoadingAutocomplete: false,
        autocomplete: []
      };
    case 'FETCH_GEOCODING_MAP_SUCCESS':
      return {
        ...state,
        map: {
          ...state.map,
          address: getReversGeocodingAddress(payload.data)
        },
        assetMapSelected: null
      };
    case 'COMPARABLES_RESET_FILTERS':
      return {
        ...state,
        filtersApplied: { ...state.defaultValues.filtersApplied },
        map: { ...state.defaultValues.map },
        assetMapSelected: null
      };
    case 'COMPARABLES_FILTERS':
      const rangeFilters = (state.filters?.main || [])
        .concat((state.filters?.secondary || []).flat())
        .filter(filter => filter.filter_type === 'range');

      const newFilters = getNewRangeFilters(payload, rangeFilters);

      return {
        ...state,
        filtersApplied: newFilters
      };

    case 'COMPARABLES_FILTERS_BY_ANCHOR':
      const rangeFiltersByAnchor = (state.filtersByAnchor?.main || [])
        .concat((state.filtersByAnchor?.secondary || []).flat())
        .filter(filter => filter.filter_type === 'range');

      const newFiltersByAnchor = getNewRangeFilters(payload.filters, rangeFiltersByAnchor);
      const existingIndex = state.filtersByAnchor.findIndex(
        item => item.idAnchor === payload.anchorId
      );

      let updatedArray = state.filtersByAnchor;

      if (existingIndex !== -1) {
        let newArray;
        newArray = state.filtersByAnchor.map((item, index) => {
          if (index === existingIndex) {
            return {
              ...item,
              filters: newFiltersByAnchor
            };
          } else {
            return item;
          }
        });
        updatedArray = newArray;
      } else {
        updatedArray.push({
          filters: newFiltersByAnchor,
          idAnchor: payload.anchorId
        });
      }

      return {
        ...state,
        filtersByAnchor: updatedArray
      };
    case 'SET_ANCHOR_ID':
      return {
        ...state,
        idAnchor: payload
      };
    case 'SET_COMPARABLES_LIST_CALL_ENDED':
      return {
        ...state,
        comparablesListCallEnded: payload
      };
    case 'COMPARABLES_SET_SELECTED':
      return {
        ...state,
        list: {
          ...state.list,
          rows: formatSelectedRows(state.list.rows, payload)
        },
        selectedAssets: formatSelectedAssets(state.selectedAssets, payload)
      };
    case 'SET_SELECTED_ASSETS_BY_MAP':
      return {
        ...state,
        selectedAssets: { ...state.selectedAssets, [payload.id]: payload }
      };
    case 'COMPARABLES_REMOVE_SELECTED':
      const smartValuations = { ...state.selectedAssets };
      const [current] = payload;
      delete smartValuations[current.id];

      return {
        ...state,
        list: {
          ...state.list,
          rows: formatSelectedRows(state.list.rows, payload)
        },
        selectedAssets: { ...smartValuations }
      };
    case 'COMPARABLES_RESET':
      const stateByAnchor = {
        ...initialState,
        filtersByAnchor: state.filtersByAnchor
      };
      return stateByAnchor;
    case 'SET_GRID_API':
      return {
        ...state,
        gridApi: payload
      };

    case 'RESET_FILTERS_BY_BUTTON_SUCCESS':
      return {
        ...state,
        defaultFiltersAsset: payload
      };

    default:
      return state;
  }
};

export default reducer;
