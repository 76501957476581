import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const httpRequest = baseUrl => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set('Authorization', token);
      return headers;
    }
  });
};

export const httpStrapiRequest = baseUrl => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.strapiToken;
      headers.set('Authorization', token);
      return headers;
    }
  });
};
