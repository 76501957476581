import StaticLayout from '../components/StaticLayout';
import PATHS from '../routes/paths';
import { Button, IconArrowLeft, Grid, TextLink } from '@uda/bento-components';
import slender from '../assets/images/404.gif';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { closeNotification } from '../actions/notification';
import useFlag from '../utils/hooks/useFlag';

const StyledImage = styled.figure`
  margin-right: 30px;
  > img {
    max-width: 100%;
    display: block;
  }
`;

const StyledContent = styled.article`
  h2 {
    color: ${({ theme }) => theme.color.charcoal800};
    margin-bottom: 32px;
  }

  h3 {
    color: ${({ theme }) => theme.color.charcoal600};
    font-size: 24px;
    margin-bottom: 32px;
  }

  p {
    color: ${({ theme }) => theme.color.charcoal500};
  }

  button {
    margin-top: 24px;
  }
`;

export const NotFoundComponent = () => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const fotocasaProvider = useFlag('fotocasa_provider');

  const handleClick = () => {
    if (isAuthenticated) {
      history.length > 2 ? history.goBack() : history.push(PATHS.base);
    } else {
      history.push(PATHS.signIn);
    }
    dispatch(closeNotification());
  };

  return (
    <Grid
      columns="30% 1fr"
      gap="20px"
      style={{
        height: 'calc(100vh - 146px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <StyledImage>
        <img src={slender} alt="404" />
      </StyledImage>
      <StyledContent>
        <h2>
          <FormattedMessage id="errors.404.title" />
        </h2>
        <h3>
          <FormattedMessage id="errors.404.description" />
        </h3>
        <p className="p1b">
          <FormattedMessage id="errors.404.code" />
        </p>
        <p className="p1b">
          <FormattedMessage
            id="errors.404.support"
            values={{
              link: (
                <TextLink
                  href={PATHS.contactUs}
                  title={intl.formatMessage({ id: 'errors.404.contact' })}
                  className="p1b primary"
                  target="_blank"
                >
                  <FormattedMessage id="errors.404.contact" />
                </TextLink>
              )
            }}
          />
        </p>
        <Button block size="large" onClick={handleClick}>
          <IconArrowLeft />
          <FormattedMessage
            id={isAuthenticated ? 'errors.404.cta_auth' : 'errors.404.cta_not_auth'}
          />
        </Button>
      </StyledContent>
    </Grid>
  );
};

const PageNotFoundContainer = () => {
  return (
    <StaticLayout>
      <NotFoundComponent />
    </StaticLayout>
  );
};

export default PageNotFoundContainer;
