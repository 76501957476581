import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import AuthFooter from './AuthFooter';
import AuthBrand from './AuthBrand';
import LoadingLayout from '../../components/LoadingLayout';
import NotificationTranslate from '../../components/Notifications/NotificationTranslate';
import { useBreakpoint } from '../../utils/hooks/useBreakpoint';
import PATHS from '../../routes/paths';
import { closeNotification } from '../../actions/notification';
import { STATUS_CODE } from '../../constants.js';
import { setLanguage } from '../../actions/user';
import { isLanguageSupported } from '../../translations';
import { getQueryParam } from '../../utils';
import { history } from '../../App';
import { LoginFooterContainer, LoginLogoWrapper, LoginLogo } from 'Auth/forms/AuthForms.styles';
import PulseLogo from '../../assets/images/logo-pulse-24.svg';

const WrapperNotification = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.spacings.medium1};
  right: ${({ theme }) => theme.spacings.medium1};
  z-index: 3;
  transition: all 300ms ease-in-out;
  > aside {
    width: auto;
  }

  @media only screen and (max-width: 1023px) {
    top: 10px;
    right: 10px;
    left: 10px;
    bottom: unset;
    right: unset;
    width: calc(100% - 20px);
    position: fixed;
  }
`;

const StyledGrid = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 8% 84% 8%;

  @media only screen and (max-width: 1023px) {
    overflow: auto;
    grid-template-rows: 15% 5% 65% 15%;
  }
`;

const transformErrors = error => {
  if (error.code === STATUS_CODE.UNAUTHORIZED && error.action === 'SIGN_IN_FAIL') {
    return {
      id: `auth.sign_in.fail`,
      values: {
        link: (
          <Link to={PATHS.resetPassword}>
            <FormattedMessage id={'auth.sign_in.reset_password'} />
          </Link>
        )
      }
    };
  }

  return { id: error.message };
};

const AuthLayout = ({ children }) => {
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const notification = useSelector(state => state.notification);
  const isLoading = useSelector(state => state.auth.token && !state.auth.isAuthenticated);
  const location = useLocation();
  let lang = getQueryParam(location, 'lang', null);

  useEffect(() => {
    if (lang && isLanguageSupported(lang)) {
      const query = new URLSearchParams(location.search);
      query.delete('lang');

      dispatch(setLanguage(lang));
      history.push(query.toString().length ? `${location.pathname}?${query}` : location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { isOpen, variant } = notification;
    isOpen && variant === 'error' && dispatch(closeNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleClose = () => {
    dispatch(closeNotification());
  };

  const { isOpen } = notification;

  return isLoading ? (
    <LoadingLayout full />
  ) : (
    <StyledGrid mobile={breakpoint === 'mobile'}>
      {isOpen ? (
        <WrapperNotification hasNotify={isOpen}>
          <NotificationTranslate
            transformErrors={transformErrors}
            notification={notification}
            onClose={handleClose}
          />
        </WrapperNotification>
      ) : null}
      <LoginLogoWrapper>
        <LoginLogo src={PulseLogo} />
      </LoginLogoWrapper>
      {breakpoint !== 'm' || breakpoint !== 's' ? <AuthBrand /> : null}
      {children}
      <LoginFooterContainer>
        <AuthFooter />
      </LoginFooterContainer>
    </StyledGrid>
  );
};

export default AuthLayout;
