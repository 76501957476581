import { useEffect, useRef, useCallback } from 'react';
import GlobalStyles from '@uda/bento-components/lib/themes/global';
import Routes from './routes';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { flattenMessages, locale, translations } from './translations';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from './context/index';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { initApplication } from './actions/app';
import { initApp } from './Auth/actions/auth';
import { userSetCookiesConfig } from './utils/cookies';
import CookiesModal from './components/CookiesModal';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-datepicker/dist/react-datepicker.css';

export const fetchUser = () => ({
  type: 'CHECK_LIVENESS',
  payload: {
    request: {
      method: 'GET',
      url: '/users/liveness/'
    }
  }
});

export const history = createBrowserHistory();

const App = () => {
  const { theme } = useAppContext();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.auth);
  const language = useSelector(state => state.user.data.language) || locale;
  const lastActivityRef = useRef(Date.now());

  const receiveMessage = evt => {
    const token = evt.data.authorization;
    if (token) dispatch(initApp({ token }));
  };

  const handleUserActivity = () => {
    lastActivityRef.current = Date.now();
  };

  const getJitter = () => {
    return Math.floor(Math.random() * 2000);
  };

  const checkAuthStatus = useCallback(async () => {
    try {
      const timeSinceLastActivity = Date.now() - lastActivityRef.current;

      // Check if user has been inactive for at least 10 seconds and is not on the /sign-in page
      if (timeSinceLastActivity >= 10000 && history.location.pathname !== '/sign-in') {
        console.log(`Checking session.. (${timeSinceLastActivity})`);
        const action = dispatch(fetchUser());
        if (action.error) {
          history.push('/sign-in');
        }
      }
    } catch (error) {
      history.push('/sign-in');
    }
  }, [dispatch]);

  const routes =
    isAuthenticated !== null ? (
      <Router history={history}>
        <Routes isAuthenticated={isAuthenticated} />
      </Router>
    ) : null;

  const needCookiesConfiguration = !userSetCookiesConfig();

  useEffect(() => {
    dispatch(initApplication());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => window.removeEventListener('message', receiveMessage);
  }, []);

  // useEffect(() => {
  //   // Monitor user activity to reset the inactivity timer
  //   window.addEventListener('mousemove', handleUserActivity);
  //   window.addEventListener('keydown', handleUserActivity);

  //   const intervalId = setInterval(checkAuthStatus, 10000 + getJitter());
  //   return () => {
  //     clearInterval(intervalId);
  //     window.removeEventListener('mousemove', handleUserActivity);
  //     window.removeEventListener('keydown', handleUserActivity);
  //   };
  // }, [checkAuthStatus]);

  return (
    <IntlProvider
      locale={language}
      key={language}
      messages={flattenMessages(translations[language])}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {needCookiesConfiguration ? <CookiesModal /> : null}
        {routes}
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
