import styled from 'styled-components';

export const PulsePrimaryButton = styled.button`
  position: relative;
  width: ${props => props.width ?? '100%'};
  height: ${props => props.height ?? '100%'};
  cursor: pointer;
  background-color: rgb(23, 120, 251);
  color: rgb(255, 255, 255);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
  border-radius: 15px;
  &:hover {
    background-color: rgb(72, 170, 241);
  }
`;
