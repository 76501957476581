import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { PulsePrimaryButton } from 'components/Button/button.styles';
import {
  LoginFormContainer,
  LoginFormTitle,
  LoginFormMessage,
  LoginCreateAccountContainer
} from './AuthForms.styles';
import PATHS from 'routes/paths';

const AuthSignInForm = ({ closeNotification = () => {} }) => {
  const intl = useIntl();
  const location = useLocation();

  // Extract the 'error' query parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const errorMessage = queryParams.get('errorMessage');

  // Define the SAML login URL
  const samlLoginUrl = process.env.REACT_APP_API_BASE_URL + '/api/backoffice/sso/login/';

  const handleSamlLogin = () => {
    closeNotification();
    // Redirect to the SAML login page
    window.location.href = samlLoginUrl;
  };

  return (
    <LoginFormContainer>
      <LoginFormTitle>
        <FormattedMessage id="paths.sign_in">
          {txt => <span style={{ textTransform: 'uppercase' }}>{txt}</span>}
        </FormattedMessage>
      </LoginFormTitle>
      <LoginFormMessage>
        <FormattedMessage id="paths.sign_in_message" />
      </LoginFormMessage>
      <PulsePrimaryButton onClick={handleSamlLogin} width="60%" height="60px">
        <FormattedMessage id="auth.sign_in.sign_in_saml" />
      </PulsePrimaryButton>
      <LoginCreateAccountContainer>
        <FormattedMessage
          id="auth.sign_in.no_account"
          values={{
            link: (
              <a
                href={PATHS.demo_form}
                title={intl.formatMessage({ id: 'auth.sign_up.create' })}
                className="primary"
              >
                <FormattedMessage id="auth.sign_up.create" />
              </a>
            )
          }}
        />
      </LoginCreateAccountContainer>
      {/* Display the error message if it exists */}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </LoginFormContainer>
  );
};

export default AuthSignInForm;
