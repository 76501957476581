import * as Yup from 'yup';
import { InputField, FormGroup, Avatar, Button, IconFile, IconCheck } from '@uda/bento-components';
import styled, { useTheme } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { getValueFormatted, telRegExp } from '../../utils';
import SettingsActions from './SettingsActions';
import useFlag from '../../utils/hooks/useFlag';
import { FOTOCASA_CONTACT_EMAIL, UDA_CONTACT_EMAIL } from '../../constants.js';

const StyledComponentWrapper = styled.div`
  @media only screen and (max-width: 600px) {
    padding: ${({ theme: { spacings } }) => `${spacings.small3} ${spacings.medium1}`};
    margin-bottom: ${({ theme }) => theme.spacings.large2};
  }
`;

const MutedText = styled.div`
  ${({ theme }) => theme.texts.p1};
  margin-bottom: ${({ theme }) => theme.spacings.small2};

  ul {
    padding-left: 15px;
  }
`;

const GdprStatusPendingText = styled.div`
  ${({ theme }) => theme.texts.p1b};
  margin-top: ${({ theme }) => theme.spacings.small2};
  color: ${({ theme }) => theme.color.carrot600};
`;

const GdprStatusCompletedText = styled.div`
  ${({ theme }) => theme.texts.p2b};
  margin-top: ${({ theme }) => theme.spacings.small2};
  color: ${({ theme }) => theme.color.charcoal500};
`;

const StyledHeading = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacings.small4};
`;

const StyledContainer = styled.div`
  max-width: 340px;
`;

const DangerZoneContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium3};
`;

const GdprContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium3};
`;

const SettingsAccount = ({ initialState, onSubmit, onGdprRequest, onFetchGdprStatus }) => {
  const intl = useIntl();
  const language = useSelector(state => state.user.data.language);
  const theme = useTheme();
  const { name, surname, email, tel, avatar } = initialState;

  const { gdprStatus, gdprRequestDate } = useSelector(state => state.settings);

  useEffect(() => {
    onFetchGdprStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Mailto Content Variables
  const fotocasaProvider = useFlag('fotocasa_provider');
  const contactEmail = fotocasaProvider ? FOTOCASA_CONTACT_EMAIL : UDA_CONTACT_EMAIL;
  const subject = intl.formatMessage({ id: 'settings.account.delete_subject' });
  const body = intl.formatMessage(
    { id: 'settings.account.delete_body' },
    { userName: `${name} ${surname}` }
  );
  intl.formatMessage({ id: 'paths.terms' });

  const validationSchema = Yup.object({
    name: Yup.string().required(intl.formatMessage({ id: 'errors.required' })),
    surname: Yup.string().required(intl.formatMessage({ id: 'errors.required' })),
    tel: Yup.string().matches(telRegExp, intl.formatMessage({ id: 'errors.invalid_tel' }))
  });

  const formik = useFormik({
    initialValues: {
      name,
      surname,
      tel,
      email
    },
    validationSchema: validationSchema,
    async onSubmit({ name, surname, tel }, { setSubmitting }) {
      await onSubmit({ name, surname, tel });
      setSubmitting(false);
    }
  });

  function handleKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const { handleSubmit, isSubmitting, errors, touched, handleBlur, handleChange, values } = formik;

  const isSubmitDisabled =
    isSubmitting || !!errors.name || !!errors.surname || !values.name || !values.surname;

  return (
    <StyledComponentWrapper>
      <StyledHeading>
        <FormattedMessage id="settings.account.title" />
      </StyledHeading>
      <Avatar
        size="large"
        initialsNum={name && surname ? 2 : 0}
        style={{ marginBottom: theme.spacings.small4 }}
        imgProps={avatar}
      >
        {name && surname ? `${name} ${surname}` : null}
      </Avatar>
      <StyledContainer>
        <form
          onKeyDown={handleKeyDown}
          onSubmit={handleSubmit}
          className={isSubmitting ? 'loading' : null}
        >
          <FormGroup>
            <InputField
              autoComplete="name"
              disabled={isSubmitting}
              error={!!errors.name && !!touched.name}
              help={errors.name && touched.name ? errors.name : ''}
              label={intl.formatMessage({ id: 'settings.account.name' })}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'settings.account.name_placeholder' })}
              required
              type="text"
              value={values.name}
            />
          </FormGroup>
          <FormGroup>
            <InputField
              autoComplete="surname"
              disabled={isSubmitting}
              error={!!errors.surname && !!touched.surname}
              help={errors.surname && touched.surname ? errors.surname : ''}
              label={intl.formatMessage({ id: 'settings.account.surname' })}
              name="surname"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'settings.account.surname_placeholder' })}
              required
              type="text"
              value={values.surname}
            />
          </FormGroup>
          <FormGroup>
            <InputField
              autoComplete="email"
              disabled={true}
              label={intl.formatMessage({ id: 'settings.account.email' })}
              name="email"
              type="email"
              value={values.email}
            />
          </FormGroup>
          <SettingsActions isSubmitDisabled={isSubmitDisabled} isSubmitting={isSubmitting} />
        </form>
        <GdprContainer>
          <StyledHeading>
            <FormattedMessage id="settings.account.gdpr.title" />
          </StyledHeading>

          <MutedText>
            <FormattedMessage id="settings.account.gdpr.info" />
          </MutedText>
          <MutedText>
            <FormattedMessage id="settings.account.gdpr.kind_title" />
          </MutedText>
          <MutedText>
            <ul>
              <li>
                <FormattedMessage id="settings.account.gdpr.kind_personal" />
              </li>
              <li>
                <FormattedMessage id="settings.account.gdpr.kind_products" />
              </li>
              <li>
                <FormattedMessage id="settings.account.gdpr.kind_logs" />
              </li>
            </ul>
          </MutedText>

          {gdprStatus && gdprRequestDate ? (
            <>
              {gdprStatus === 'pending' ? (
                <>
                  <Button variant={'secondary'} disabled={true} iconLeft={<IconCheck />}>
                    <FormattedMessage id="settings.account.gdpr.button_disabled" />
                  </Button>
                  <GdprStatusPendingText>
                    <FormattedMessage
                      id={`settings.account.gdpr.status_${gdprStatus}`}
                      values={{
                        date_requested: getValueFormatted(
                          {
                            value: gdprRequestDate,
                            format: 'date',
                            type: 'full'
                          },
                          intl,
                          language,
                          true,
                          false
                        )
                      }}
                    />
                  </GdprStatusPendingText>
                </>
              ) : null}
              {gdprStatus === 'completed' ? (
                <>
                  <Button variant={'secondary'} iconLeft={<IconFile />} onClick={onGdprRequest}>
                    <FormattedMessage id="settings.account.gdpr.button_request" />
                  </Button>
                  <GdprStatusCompletedText>
                    <FormattedMessage
                      id={`settings.account.gdpr.status_${gdprStatus}`}
                      values={{
                        date_requested: getValueFormatted(
                          {
                            value: gdprRequestDate,
                            format: 'date',
                            type: 'full'
                          },
                          intl,
                          language,
                          true,
                          false
                        )
                      }}
                    />
                  </GdprStatusCompletedText>
                </>
              ) : null}
            </>
          ) : (
            <Button variant={'secondary'} iconLeft={<IconFile />} onClick={onGdprRequest}>
              <FormattedMessage id="settings.account.gdpr.button_request" />
            </Button>
          )}
        </GdprContainer>
      </StyledContainer>
    </StyledComponentWrapper>
  );
};

export default SettingsAccount;
