import styled from 'styled-components';

// login

export const LoginImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1;
  grid-row: 2/3;
  @media only screen and (max-width: 1023px) {
    grid-column: 2;
    grid-row: 1/3;
  }
`;

export const LoginImageWrapper = styled.img`
  width: 480px;
  @media only screen and (max-width: 1023px) {
    position: absolute;
    width: 200px;
    right: 0;
    top: 0;
  }
`;

export const LoginLogo = styled.img`
  width: 100px;
  @media only screen and (max-width: 1023px) {
    width: 200px;
  }
`;

export const LoginLogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  grid-row: 1/2;
  grid-column: 1/3;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1023px) {
    grid-column: 1;
    grid-row: 1;
  }
`;

export const LoginFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  grid-column: 2;
  grid-row: 1/3;
  @media only screen and (max-width: 1023px) {
    grid-row: 3/4;
    grid-column: 1/3;
  }
`;
export const LoginFormTitle = styled.span`
  width: 60%;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-align: start;
  letter-spacing: 5px;
`;
export const LoginFormMessage = styled.span`
  width: 60%;
  color: #000;
  font-size: 32px;
  font-weight: 300;
`;

export const LoginFooterContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-column: 1/3;
  grid-row: 3;
  background-color: #eff2f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  @media only screen and (max-width: 1023px) {
    grid-row: 4;
    grod-column: 1/3;
    padding: 0 10px;
  }
`;

export const LoginFooterBrand = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  @media only screen and (max-width: 1023px) {
    width: 100%;
    justify-content: center;
  }
`;
export const LoginFooterLinks = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 50px;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const LoginCreateAccountContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
`;

export const LoginCreateAccountMessage = styled.span`
  width: 60%;
  color: #000;
  font-size: 14px;
  font-weight: 500;
`;
export const LoginCreateAccountButton = styled.link`
  width: 60%;
  color: rgb(23, 120, 251);
  font-size: 14px;
  font-weight: 500;
`;
