import { FormattedMessage, useIntl } from 'react-intl';
import PATHS from '../../routes/paths';
import { useHistory } from 'react-router-dom';
import {
  LoginFooterContainer,
  LoginFooterBrand,
  LoginFooterLinks
} from 'Auth/forms/AuthForms.styles';
import { ReactComponent as UdaSymbol } from '../../assets/images/logo-symbol-uDA-24.svg';

const AuthFooter = () => {
  const intl = useIntl();
  const history = useHistory();

  const isSignUpPage = () =>
    history.location.pathname === PATHS.signUp ||
    history.location.pathname.startsWith(PATHS.checkEmail);

  return (
    <LoginFooterContainer>
      {!isSignUpPage() ? (
        <>
          <LoginFooterBrand>
            <UdaSymbol />
            <span>
              powered by <strong>urbanDataAnalytics</strong> by Accumin
            </span>
          </LoginFooterBrand>
          <LoginFooterLinks>
            <a
              href={PATHS.demo_form}
              title={intl.formatMessage({ id: 'paths.contact_us' })}
              className="primary"
            >
              <FormattedMessage id="paths.contact_us" />
            </a>
            <a
              href={PATHS.privacyPolicy}
              title={intl.formatMessage({ id: 'legal.privacy_policy_url' })}
              className="primary"
            >
              <FormattedMessage id="paths.privacy" />
            </a>
            <a
              href={PATHS.termsOfUse}
              title={intl.formatMessage({ id: 'legal.terms' })}
              className="primary"
            >
              <FormattedMessage id="paths.terms" />
            </a>
          </LoginFooterLinks>
        </>
      ) : null}
    </LoginFooterContainer>
  );
};

export default AuthFooter;
