import PATHS from '../routes/paths';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthLayout from '../Auth/components/AuthLayout';
import PageTitle from '../components/PageTitle';
import useFlag from '../utils/hooks/useFlag';

const AuthRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const showFotocasaTitle = useFlag('show_fotocasa_title');
  const showFotocasaFavicon = useFlag('show_fotocasa_favicon');

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Redirect to={PATHS.base} />
        ) : (
          <AuthLayout>
            {/* <PageTitle
              title={showFotocasaTitle ? 'Fotocasa.es: ' : 'Pulse'}
              section={showFotocasaTitle ? 'Fotocasa Inversión' : ''}
              showFotocasaFavicon={showFotocasaFavicon}
            /> */}
            <Component {...props} />
          </AuthLayout>
        )
      }
    />
  );
};

export default AuthRoute;
